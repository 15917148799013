import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { IConfiguration } from '@shared/types/configuration';

@Injectable()
export class SettingsProvider {
    private config?: IConfiguration;
    public configLoaded: ReplaySubject<boolean>;

    constructor(private http: HttpClient) {
        this.configLoaded = new ReplaySubject(1);
    }

    getUrl(url: string): string {
        return environment.urlPrefix + url;
    }

    public loadConfiguration(): Observable<any> {
        return this.http
            .get<IConfiguration>(this.getUrl('api/configuration'))
            .pipe(tap(config => {
                this.config = config;
                this.configLoaded.next(!!config);
            }));
    }

    public get configuration(): IConfiguration | undefined {
        return this.config;
    }
}
